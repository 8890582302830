<template>
  <div class="page-box page-job  bg-white">
    <ban-view :banStyle="banStyle"></ban-view>
    <div class="bxb-con bxb-p50">
      <div class="job-content ">
        <div class="top">
          <h6>客户经理（赎楼业务）</h6>
          <div class="list">
            <span>招聘类别：销售</span>
            <span>招聘人数：不限</span>
          </div>
        </div>
        <div class="content">
          <dl>
            <dt>岗位职责：</dt>
            <dd>
              1、负责公司赎楼业务营销推广，进行新客户开发及老客户维护，接洽意向客户，对客户要求及意向进行反馈，并持续跟进意向客户，维护好良好的客户关系；
            </dd>
            <dd>
              2、负责收集并更新区域行业动态，提出营销策略调整方案和合理化建议，提供市场咨询及行业发展动态；
            </dd>
            <dd>
              3、负责赎楼业务渠道的挖掘、工作对接，负责赎楼业务的具体操作，监控业务进度，督促赎楼借款的回收及结算；
            </dd>
            <dd>4、积极拓展业务渠道，与合作单位建立良好的业务渠道；</dd>
            <dd>5、负责所属业务的初审，控制并降低业务风险。</dd>
          </dl>

          <dl>
            <dt>任职要求：</dt>
            <dd>
              1、大专以上学历，金融、工商管理、市场营销相关专业；
            </dd>
            <dd>
              2、三年以上房产、金融投资、担保、赎楼业务工作经验，有现金赎楼和红本抵押业务资源者优先。
            </dd>
            <dd>
              3、善于把握市场动态创造机会、发挥营销动能；
            </dd>
            <dd>4、对业务的各个环节有风险识别和控制的能力；</dd>
            <dd>5、工作认真负责，具有很强的敬业精神和责任心；</dd>
            <dd>6、具备良好的沟通能力、时间管理能力和抗压能力。</dd>
            <dd>7、优秀的应届毕业生可适当放宽；</dd>
          </dl>
        </div>
      </div>

      <div class="job-content bxb-m30-top">
        <div class="top">
          <h6>融资总监</h6>
          <div class="list">
            <span>招聘类别：金融</span>
            <span>招聘人数：1</span>
          </div>
        </div>
        <div class="content">
          <dl>
            <dt>岗位职责：</dt>
            <dd>
              1、开发寻找融资资本、规划融资项目，并组织开展项目融资工作；
            </dd>
            <dd>
              2、与银行、信托、券商、基金等金融机构建立并保持良好的合作关系，不断开发、拓展公司融资新渠道；
            </dd>
            <dd>
              3、在与金融机构的融资交易中，就交易的条款与条件与对方进行磋商和谈判；
            </dd>
            <dd>4、负责拓展并维护公司与资本市场的关系；</dd>
            <dd>5、 完成公司总经理安排的相关工作。</dd>
          </dl>

          <dl>
            <dt>任职要求：</dt>
            <dd>
              1、40岁以上；本科及以上学历，投资、金融或其他经济类相关专业；
            </dd>
            <dd>
              2、具有很强的财务分析能力、行业研究能力；
            </dd>
            <dd>
              3、熟悉创新型投资方式，有银行、券商、信托、基金等融资渠道人脉关系者优先考虑；
            </dd>
            <dd>
              4、具有良好的敬业精神、创新意识和团队合作精神，行业内具备较好的口碑和信誉。
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BanView from "components/content/banView/BanView";
export default {
  name: "Job",
  components: {
    BanView
  },
  data() {
    return {
      banStyle: {
        height: "300px",
        backgroundImage: 'url("https://ylxd.xcongzb.com/img/ban-join.jpg")'
      }
    };
  }
};
</script>

<style lang="scss" scoped>
.job-content {
  background: #f7f7f7;
  padding: 30px 40px;
  .top {
    h6 {
      font-size: 24px;
      font-weight: bold;
    }
    .list {
      padding: 5px 0 10px;
      span {
        padding-right: 20px;
        font-size: 16px;
      }
    }
  }
  .content {
    dt {
      font-size: 16px;
      font-weight: bold;
    }

    dd {
      font-size: 14px;
    }

    line-height: 2;
  }
}
</style>

<template>
  <div class="ban-box" :style="banStyle"></div>
</template>

<script>
export default {
  name: "BanView",
  props: {
    banStyle: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      widthView: "",
      heightView: ""
    };
  },
  created() {
    this.widthView = window.innerWidth;
    this.heightView = window.innerHeight;
  }
};
</script>

<style lang="scss" scoped>
.ban-box {
  width: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: top center;
}
</style>
